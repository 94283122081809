<template>
  <div class="agreement">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>协议管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="box">
      <div class="select">
        <span>协议类型</span>
        <el-input v-model="searchWords" placeholder="请输入查找内容"></el-input>
        <el-button type="primary">查找</el-button>
      </div>
    </div>
    <el-table style="width: 100%" border show-summary :data="tableData">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="id" width="80"> </el-table-column>
      <el-table-column prop="name" label="协议名称"> </el-table-column>
      <el-table-column prop="address" label="操作" width="150">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="onEdit(row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { aboutus } from "../../api/aboutus";
export default {
  name: "Agreement",
  data() {
    return {
      value: "",
      searchWords: "", // 搜索关键词
      pageSize: 10,
      currentPage: 1,
      tableData: [],
    };
  },
  created() {
    this.getAboutusList();
  },
  methods: {
    onEdit(scope) {
      console.log(scope);
      this.$router.push(`/editAgree/${scope.id}`);
    },
    async getAboutusList() {
      const { data } = await aboutus({
        pageSize: this.pageSize,
        currentPage: this.currentPage,
      });
      this.tableData = data.list;
    },
    onSizeChange(num) {
      this.pageSize = num;
      this.getAboutusList();
    },
    onCurrentChange(num) {
      this.pageSize = num;
    },
  },
};
</script>
<style lang="less" scoped>
.agreement {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .box {
    width: 98%;
    margin: 0 auto;
    // background-color: #f2f2f2;
    padding: 10px 10px 50px;
    margin-top: 50px;
    .el-input {
      margin: 0 20px;
      width: 200px;
      height: 55px;
    }
  }
  /deep/.el-table {
    .el-table__header-wrapper {
      .has-gutter {
        tr {
          th {
            text-align: center;
          }
        }
      }
    }
    .el-table__footer-wrapper {
      display: none;
    }
    .el-table__body {
      tbody {
        .el-table__row {
          td {
            text-align: center;
          }
        }
      }
    }
  }
  .el-pagination {
    margin-top: 30px;
  }
}
</style>